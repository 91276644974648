<template>
  <b-overlay :show="show" rounded="sm" spinner-small spinner-variant="primary">
    <template #overlay>
      <div class="text-center">
        <b-spinner v-show="show" type="grow" label="Loading..." />
        <h4 id="cancel-label">
          {{ $t("loader") }}
        </h4>
      </div>
    </template>
    <div class="auth-wrapper auth-v2 login-page">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <b-link class="d-none d-lg-block brand-logo" :to="{ name: 'index' }">
          <img
            class="logo"
            src="../../../assets/images/logo/aic.png"
            style="height: 50px"
            alt="brand-logo"
          />

          <!-- <h2 class="brand-text text-primary ml-1">
          NFT Dubai
        </h2> -->
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col lg="4" class="align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <!-- Brand logo-->
            <b-link class="d-block d-md-none my-2" :to="{ name: 'index' }">
              <img
                class="logo"
                src="../../../assets/images/logo/aic.png"
                style="max-width: 90%"
                alt="brand-logo"
              />
            </b-link>
            <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
              {{ $t("register.welcome") }} 👋
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t("register.signInMSG") }}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <!-- email -->
                <b-form-group
                  :label="$t('register.email')"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{
                      $t("register.password")
                    }}</label>
                    <b-link :to="{ name: 'auth-forgot-password' }">
                      <small>{{ $t("register.forgetPassword") }}</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    {{ $t("register.rememberMe") }}
                  </b-form-checkbox>
                </b-form-group>
                <b-alert
                  v-if="errorMessage"
                  show
                  variant="warning"
                  class="warning"
                  style="color: red"
                >
                  {{ errorMessage }}
                </b-alert>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t("register.signIn") }}
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>{{ $t("register.new") }} </span>
              <b-link :to="{ name: 'auth-register' }">
                <span>&nbsp;{{ $t("register.createNewAcc") }}</span>
              </b-link>
            </b-card-text>

            <!-- divider -->
            <div class="divider my-2">
              <div class="divider-text">
                {{ $t("register.or") }}
              </div>
            </div>

            <!-- social buttons -->
            <div class="auth-footer-btn d-flex justify-content-center">
              <b-button
                variant="facebook"
                href="javascript:void(0)"
                @click="googleSignIn('facebook')"
              >
                <!-- <feather-icon
                icon="FacebookIcon"
                @click="googleSignIn('facebook')"
              /> -->
                <i class="fa-brands fa-facebook" />
              </b-button>
              <!-- <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button> -->
              <b-button variant="google" @click="googleSignIn('google')">
                <!-- <feather-icon icon="MailIcon" /> -->
                <i class="fa-brands fa-google" />
              </b-button>
              <!-- <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button> -->
            </div>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
// eslint-disable-next-line import/no-cycle
import store from "@/store/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions } from "vuex";
import {
  BRow,
  BOverlay,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BSpinner,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BOverlay,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      show: false,
      provider: null,
      user: null,
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/web elements-03.png"),

      // validation rules
      required,
      email,
      errorMessage: "",
    };
  },
  computed: {
    role() {
      return this.$store.getters["auth/getRole"];
    },
    getUser() {
      return this.$store.getters["auth/user"];
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    if (localStorage.getItem("language") === "ar") {
      document.documentElement.setAttribute("dir", "rtl");
      document.body.style.fontFamily = "Tajawal";
    } else {
      document.documentElement.setAttribute("dir", "ltr");
      // document.body.style.fontFamily = 'Montserrat,Helvetica,Arial, serif'
      document.body.style.fontFamily = "nftbplus,sans-serif";
    }
  },
  methods: {
    ...mapActions({
      signIn: "auth/login",
      socialRegister: "auth/socialRegister",
    }),
    async googleSignIn(type) {
      if (type === "facebook") {
        this.provider = new FacebookAuthProvider();
        this.provider.setCustomParameters({
          display: "popup",
        });
      } else if (type === "google") {
        this.provider = new GoogleAuthProvider();
      } else if (type === "linkedIn") {
        this.provider = new GoogleAuthProvider();
      }

      const auth = getAuth();
      await signInWithPopup(auth, this.provider)
        .then((result) => {
          this.show = true;
          const loginData = new FormData();
          loginData.append(
            "displayName",
            result.user.providerData[0].displayName.replace(/\s/g, "")
          );
          loginData.append("email", result.user.providerData[0].email);
          loginData.append("photoURL", result.user.providerData[0].photoURL);
          loginData.append(
            "serviceProviderId",
            result.user.providerData[0].uid
          );
          loginData.append(
            "serviceProvider",
            type === "facebook" ? "facebook" : "google"
          );
          loginData.append("deviceType", "web");
          loginData.append("fcmId", localStorage.getItem("fcmid"));

          this.socialRegister(loginData)
            .then(() => {
              if (this.role === "Employee" || this.role === "Super-admin") {
                this.$router.replace("/dashboard");
              } else if (this.role === "Guest") {
                this.$router.replace("/account-type");
              } else if (this.role === "Partner") {
                this.$router.replace("/partner-dashboard");
              } else if (this.role === "User") {
                this.$router.push({
                  name: "userprofile",
                  params: { username: this.getUser.username },
                });
              } else {
                this.$router.replace("/index");
              }
              this.show = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `${this.$t("register.welcome")} ${
                    this.getUser.username
                  }`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `${this.$t("register.successMessage")} ${
                    this.role
                  } .${this.$t("register.successMessage2")}`,
                },
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          this.show = false;
          this.errorMessage = error.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.code ? error.code : "unfortunately!",
              icon: "AlertTrainingIcon",
              variant: "danger",
              text: error.message
                ? error.message
                : "something went wrong with sign in",
            },
          });
        });
    },
    async login() {
      await this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.show = true;
          const userData = new FormData();
          userData.append("email", this.userEmail);
          userData.append("password", this.password);
          userData.append("remember_me", 1);
          userData.append("deviceType", "web");
          userData.append("fcmId", localStorage.getItem("fcmid"));
          this.signIn(userData)
            .then(() => {
              if (this.role === "Employee" || this.role === "Super-admin") {
                this.$router.replace("/dashboard");
              } else if (this.role === "Guest") {
                this.$router.replace("/account-type");
              } else if (this.role === "Partner") {
                this.$router.replace("/partner-dashboard");
              } else if (this.role === "User") {
                this.$router.push({
                  name: "userprofile",
                  params: { username: this.getUser.username },
                });
              } else {
                this.$router.replace("/index");
              }
              this.show = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `${this.$t("register.welcome")} ${
                    this.getUser.username
                  }`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `${this.$t("register.successMessage")} ${
                    this.role
                  } .${this.$t("register.successMessage2")}`,
                },
              });
            })
            .catch(() => {
              this.show = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("register.sorry"),
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: this.$t("register.errorMSG"),
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.login-page label {
  font-size: 16px;
  font-weight: 500;
}
html[dir="rtl"] svg.feather {
  transform: rotate(0);
}
</style>
<style scoped>
.img-fluid {
  max-width: 56%;
}
</style>
